import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Image from "gatsby-image"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <section className="hero is-dark is-bold is-medium homepage-hero">
      {data.allWordpressAcfOptions.edges.map(wpOpt => (
        <div key={wpOpt.node.id} className="homepage-hero-bg">
          <Image
            fluid={
              wpOpt.node.options.hero_image.localFile.childImageSharp.fluid
            }
            alt={data.wordpressSiteMetadata.name}
          />
        </div>
      ))}
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-size-1-desktop">
            {data.wordpressSiteMetadata.name}
          </h1>
          <h2 className="subtitle">{data.wordpressSiteMetadata.description}</h2>
        </div>
      </div>
    </section>

    <section className="container section">
      <div className="columns newest-products">
        {data.allWordpressWpVehicles.edges.map(post => (
          <div key={post.node.wordpress_id} className="column">
            <Link
              to={`/vehicle/${post.node.slug}`}
              style={{
                display: "flex",
                color: "black",
                textDecoration: "none",
              }}
            >
              <div className="card">
                {post.node.featured_media && (
                  <div className="card-image">
                    <Image
                      fluid={
                        post.node.featured_media.localFile.childImageSharp.fluid
                      }
                      alt={post.node.title}
                    />
                  </div>
                )}
                <div className="card-content">
                  <div className="content">
                    {post.node.title && (
                      <h3
                        dangerouslySetInnerHTML={{ __html: post.node.title }}
                        style={{ marginBottom: 0 }}
                      />
                    )}
                    {post.node.excerpt && (
                      <div
                        dangerouslySetInnerHTML={{ __html: post.node.excerpt }}
                      />
                    )}
                    {post.node.acf.product_price && (
                      <span className="tag is-medium">
                        ${post.node.acf.product_price}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </section>
    <section className="container section">
      <div className="tile is-ancestor">
        <div className="tile is-parent is-vertical is-8">
          <article className="tile is-child notification is-primary">
            <h2 className="subtitle">Who We Are</h2>
            <div className="content">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Repellendus ipsam vel esse excepturi? Placeat, unde aspernatur
                et magnam vero suscipit illum deserunt excepturi consequatur
                quibusdam quos libero labore, deleniti praesentium.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                nobis, quaerat laboriosam, ad odit rerum quod error quae
                quisquam in accusantium nam. Beatae sapiente rem odio cumque
                ratione. Modi, est?
              </p>
            </div>
          </article>
        </div>
        <div className="tile is-parent is-vertical">
          <article className="tile is-child notification is-info">
            <h2 className="subtitle">Get in Touch</h2>
            <div className="content">
              <form>
                <div className="field">
                  <p className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Name"
                    ></input>
                  </p>
                </div>
                <div className="field">
                  <p className="control">
                    <input
                      className="input"
                      type="email"
                      placeholder="Email"
                    ></input>
                  </p>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <textarea
                          className="textarea"
                          placeholder="How we can help you?"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control">
                  <button className="button is-primary">Submit</button>
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>
    </section>
    <section className="hero is-light is-bold is-small">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h3 className="title">Looking for H1 parts?</h3>
          <h4 className="subtitle">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </h4>
          <a className="button is-light is-inverted is-outlined" href="/parts">
            Browse our selection of parts
          </a>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    wordpressSiteMetadata {
      name
      description
    }
    allWordpressAcfOptions {
      edges {
        node {
          id
          options {
            hero_image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpVehicles {
      edges {
        node {
          wordpress_id
          title
          excerpt
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          acf {
            product_price
          }
        }
      }
    }
  }
`
